// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.webp";

export const date = new Date().getFullYear();

export default {
  brand: {
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/lameilleurecrypto",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/lameilleurecrypto/",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCDEe7n-gw8B4X4gCnveXYQQ",
    },
  ],
  menus: [
    {
      name: "info",
      items: [
        { name: "contact", route: "/contact" },
        { name: "avis", route: "/nouvel-avis" },
        { name: "affiliation", route: "/affiliation" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "mentions-legales", route: "/mentions-legales" },
        { name: "politique-confidentialite", route: "/politique-de-confidentialite" },
        { name: "cgu", route: "/conditions-generales-utilisation" },
        { name: "politique-cookies", route: "/politique-cookies" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}
    </MKTypography>
  ),
};
