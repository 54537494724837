import { Box } from "@mui/material";
import { customColor } from "assets/theme";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";


export const affiliates = {
  "Binance": {
    "url": "https://accounts.binance.com/register?ref=F13PXRPZ",
    "percent": "10"
  },
  "Coinbase": {
    "url": "https://www.coinbase.com/signup",
  },
  "Kraken": {
    "url": "https://www.kraken.com/fr/sign-up"
  },
  "KuCoin": {
    "url": "https://www.kucoin.com/ucenter/signup"
  },
  "Gemini": {
    "url": "https://exchange.gemini.com/register"
  }
}

// const handleGtmClick = (name) => {
//   // Envoyer l'événement de clic à GTM
//   if (name === "Binance") {
//     TagManager.dataLayer({
//       dataLayer: {
//         event: 'binance-click',
//         category: 'Button',
//         action: 'Click',
//         label: name + ' Link Button',
//       },
//     });
//   }
//   window.open(affiliates[name]?.url || "", '_blank', 'noopener');
// }

function Affiliate({ name, simple }) {
  const { t } = useTranslation();

  
  const handleAffiliateLink = () => {
    window.open(affiliates["Binance"].url, "_blank", "noopener noreferrer");
  };
  return <>
    <Box sx={{ m: 2 }} />
    <FilledInfoCard
      variant={simple ? "contained" : "gradient"}
      color="info"
      icon={simple ? "" : "flag"}
      title={simple ? "" : t("affil.title")}
      description={simple ? t("affil.description_simple") : ""}
      descriptionComponent={!simple && <Trans
        i18nKey="affil.description"
        values={{ name, percent: affiliates[name].percent }}
        components={{ strong: <strong style={{ color: customColor }} /> }}
      />}
      action={{
        type: name === "Binance" ? "internal" : "external",
        label: t("affil.action", { name: name }),
        button: true,
        rel: "noopener noreferrer sponsored",
        route: name === "Binance" ? "/merci-inscription-binance" : affiliates[name]?.url || "",
        onClick: () => name === "Binance" ? handleAffiliateLink() : null
      }}
    />
  </>;
}

Affiliate.propTypes = {
  simple: PropTypes.bool,
  name: PropTypes.string.isRequired
};

Affiliate.defaultProps = {
  simple: false,
};

export default Affiliate;
