// Images
const img_best_cryp_c = "https://ik.imagekit.io/qzjdsrhh6/images/articles/best-cryp-c.webp?tr=w-300";
const img_best_cryp = "https://ik.imagekit.io/qzjdsrhh6/images/articles/best-cryp.webp";
const img_best_plat_c = "https://ik.imagekit.io/qzjdsrhh6/images/articles/best-plat-c.webp?tr=w-300";
const img_best_plat = "https://ik.imagekit.io/qzjdsrhh6/images/articles/best-plat.webp";
const img_best_wall_c = "https://ik.imagekit.io/qzjdsrhh6/images/articles/best-wall-c.webp?tr=w-300";
const img_best_wall = "https://ik.imagekit.io/qzjdsrhh6/images/articles/best-wall.webp";
const img_comp_wall_c = "https://ik.imagekit.io/qzjdsrhh6/images/articles/comp-wall.webp?tr=w-300";
const img_comp_wall = "https://ik.imagekit.io/qzjdsrhh6/images/articles/comp-wall.webp";
const img_earn_mone_c = "https://ik.imagekit.io/qzjdsrhh6/images/articles/earn-mone-c.webp?tr=w-300";
const img_earn_mone = "https://ik.imagekit.io/qzjdsrhh6/images/articles/earn-mone.webp";
const img_pres_cryp_c = "https://ik.imagekit.io/qzjdsrhh6/images/articles/guide-debutants-cryptomonnaies.webp?tr=w-300";
const img_pres_cryp = "https://ik.imagekit.io/qzjdsrhh6/images/articles/guide-debutants-cryptomonnaies.webp";
const img_pres_wall_c = "https://ik.imagekit.io/qzjdsrhh6/images/articles/pres-wall-c.webp?tr=w-300";
const img_pres_wall = "https://ik.imagekit.io/qzjdsrhh6/images/articles/pres-wall.webp";
const img_cree_bina_c = "https://ik.imagekit.io/qzjdsrhh6/images/articles/cree-bina-c.webp?tr=w-300";
const img_cree_bina = "https://ik.imagekit.io/qzjdsrhh6/images/articles/cree-bina.webp";

import { article as best_cryp } from "../../pages/Presentation/articles/content/best-cryp.js";
import { article as best_plat } from "../../pages/Presentation/articles/content/best-plat.js";
import { article as best_wall } from "../../pages/Presentation/articles/content/best-wall.js";
import { article as comp_wall } from "../../pages/Presentation/articles/content/comp-wall.js";
import { article as earn_mone } from "../../pages/Presentation/articles/content/earn-mone.js";
import { article as pres_cryp } from "../../pages/Presentation/articles/content/pres-cryp.js";
import { article as pres_wall } from "../../pages/Presentation/articles/content/pres-wall.js";
import { article as cree_bina } from "../../pages/Presentation/articles/content/cree-bina.js";

export const articles = {
    "home": {
      img: "la-meilleure-crypto.webp",
    },
    "pres-cryp": {
      image: img_pres_cryp,
      image_c: img_pres_cryp_c,
      content: pres_cryp,
      updateDate: "06.10.2024",
      img: "articles/guide-debutants-cryptomonnaies.webp",
    },
    "best-cryp": {
      image: img_best_cryp,
      image_c: img_best_cryp_c,
      content: best_cryp,
    },
    "pres-wall": {
      image: img_pres_wall,
      image_c: img_pres_wall_c,
      content: pres_wall,
    },
    "best-wall": {
      image: img_best_wall,
      image_c: img_best_wall_c,
      content: best_wall,
    },
    "comp-wall": {
      image: img_comp_wall,
      image_c: img_comp_wall_c,
      content: comp_wall,
      banner: true,
      updateDate: "06.10.2024",
    },
    "best-plat": {
      image: img_best_plat,
      image_c: img_best_plat_c,
      content: best_plat,
      banner: true,
    },
    "earn-mone": {
      image: img_earn_mone,
      image_c: img_earn_mone_c,
      content: earn_mone,
    },
    "cree-bina": {
      image: img_cree_bina,
      image_c: img_cree_bina_c,
      content: cree_bina,
      updateDate: "03.10.2024",
    },
  };


  