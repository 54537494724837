import { content as affiliation } from "../../pages/Presentation/articles/legal/affiliation.js";
import { content as confident } from "../../pages/Presentation/articles/legal/confident.js";
import { content as cookie } from "../../pages/Presentation/articles/legal/cookie.js";
import { content as mention } from "../../pages/Presentation/articles/legal/mention.js";

const img_legal = "https://ik.imagekit.io/qzjdsrhh6/images/legal.webp";

import { content as cgu } from "../../pages/Presentation/articles/legal/cgu.js";


export const legaux = {
    cgu: {
      image: img_legal,
      content: cgu,
    },
    // 'legal': {
    //   "image": img_legal,
    //   "content": legal,
    // },
    affiliation: {
      image: img_legal,
      content: affiliation,
    },
    confident: {
      image: img_legal,
      content: confident,
    },
    cookie: {
      image: img_legal,
      content: cookie,
    },
    mention: {
      image: img_legal,
      content: mention,
    },
  };
  
  