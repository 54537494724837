const siteConfig = {
    petitspas: {
      name: "La garderie aux petits pas",
      emailAdress: "lagarderieauxpetitspas@gmail.com",
      imagekitId: "o8gtczz93",
      information: false,
      posts: false,
      testimonials: false,
      binance: false,
      pages: true,
      details: true,
      themeColor: "#123456",
    },
    meilleurecrypto: {
      name: "La meilleure crypto",
      emailAdress: "lameilleurecrypto@gmail.com",
      author: "delaunayj", //Julien Delaunay
      imagekitId: "qzjdsrhh6",
      information: true,
      posts: true,
      testimonials: true,
      binance: true,
      themeColor: "#654321",
    },
  };
  export default siteConfig[process.env.REACT_APP_SITE];
  