import { useEffect } from 'react';

const LoadFavicon = () => {
  useEffect(() => {
    const site = process.env.REACT_APP_SITE;
    const faviconLink = document.querySelector("link[rel='icon']");
    if (faviconLink && site) {
      console.log(">mise à jour favicon")
      faviconLink.href = `/favicons/${site}/favicon.png?v=${Date.now()}`;
    }
  }, []);

  return null;
};

export default LoadFavicon;
