import { Grid } from "@mui/material";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

import propTypes from "prop-types";

import { useTranslation } from "react-i18next";

// Images
import profilePicture from "assets/images/profiles/profile.webp";
import { articles } from "data/articles";
import config from "config";

const ArticleProfile = ({profile, name}) => {
  const { t } = useTranslation();
  let bgImage = profilePicture;
  let authorName = "unkown";
  const updateDate = articles[name].updateDate;

  switch (true) {
    case profile === "Bruno":
      authorName = config.author;
      bgImage = profilePicture;
  }
  return (
    <Grid container spacing={3} mb={3}>
      <Grid item>
        <MKAvatar src={bgImage} alt={authorName} />
      </Grid>
      <Grid item>
        <MKTypography component="span" variant="body2">
        {t("by")} {authorName}
        </MKTypography>
      </Grid>
      <Grid item>
        <MKTypography component="span" variant="body2">
          {t("updatedAt")} {updateDate || "11.09.2024"}
        </MKTypography>
      </Grid>
    </Grid>
  );
}

ArticleProfile.propTypes = {
  profile: propTypes.string,
  name: propTypes.string
};


export default ArticleProfile;
