/* eslint-disable react/prop-types */
/* eslint-disable-next-line react/prop-types */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import react from "react";
import { useTranslation } from "react-i18next";

// @mui material components
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { Box, Grid } from "@mui/material";
import ArticleProfile from "./ArticleInfo";

import PropTypes from "prop-types";
import Markdown from "react-markdown";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

import MainFooter from "examples/Footers/MainFooter";

// import { content as legal } from "./legal/legal.js";
import { Helmet } from "react-helmet";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import Affiliate from "../Affiliate";
import Buy from "../Buy";
import { getArticleContent, getArticleImage, getArticleTitle } from "lib/articles";
import config from "config";

function Article({ name, legal }) {
  const { t } = useTranslation();

  let bgImage = getArticleImage(name);
  let content = getArticleContent(name);

  return (
    <>
      <DefaultNavbar routes={routes} source={name} transparent light />
      <MKBox bgColor="white">
        <MKBox
          minHeight={legal ? "10rem" : "30rem"}
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={{ xs: 6, sm: 12 }}>
            <Container>
              <Grid container item xs={12} justifyContent="center" mx="auto">
                <Grid container justifyContent="center">
                  <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
                    <Helmet>
                      <title>{getArticleTitle(t, name)}</title>
                      <meta name="description" content={t(`articles.${name}.summary`)} />
                    </Helmet>
                    {!legal && (
                      <>
                        <MKBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={1}
                        >
                          <MKTypography variant="h1" fontSize="2.4rem">
                            {getArticleTitle(t, name)}
                          </MKTypography>
                        </MKBox>
                        <ArticleProfile profile="Bruno" name={name} />
                      </>
                    )}
                    <Markdown
                      rehypePlugins={[rehypeRaw]}
                      remarkPlugins={[remarkGfm]}
                      // remarkPlugins={[remarkGfm]}
                      components={{
                        // Map `h1` (`# heading`) to use `h2`s.
                        pre: "div",
                        // Rewrite tags
                        h1(props) {
                          // eslint-disable-next-line react/prop-types
                          const { node, ...rest } = props;
                          return (
                            <MKBox mt={4} mb={3}>
                              <MKTypography variant={`h1`} fontSize="2.4rem" {...rest} />
                            </MKBox>
                          );
                        },
                        h2(props) {
                          // eslint-disable-next-line react/prop-types
                          const { node, ...rest } = props;
                          return (
                            <MKBox mt={3} mb={2}>
                              <MKTypography variant={`h2`} fontSize="2rem" {...rest} />
                            </MKBox>
                          );
                        },
                        h3(props) {
                          // eslint-disable-next-line react/prop-types
                          const { node, ...rest } = props;
                          return (
                            <MKBox mt={2} mb={1}>
                              <MKTypography variant={`h3`} {...rest} />
                            </MKBox>
                          );
                        },
                        h4(props) {
                          // eslint-disable-next-line react/prop-types
                          const { node, ...rest } = props;
                          return (
                            <MKBox mt={2} mb={1}>
                              <MKTypography variant={`h4`} {...rest} />
                            </MKBox>
                          );
                        },
                        h6(props) {
                          // eslint-disable-next-line react/prop-types
                          const { node, ...rest } = props;
                          return (
                            <MKTypography variant="body1" fontWeight="light" color="text" {...rest}>
                              <Icon sx={{ fontWeight: "bold" }}>check</Icon>
                            </MKTypography>
                          );
                        },
                        span: ({ node, ...props }) => {
                          if (props.className === "email") {
                            return config.emailAdress;
                          }

                          if (props.className === "pseudo") {
                            return config.author;
                          }
                          //
                          if (
                            [
                              "WhiteBookBitcoin",
                              "Ledger",
                              "LedgerS",
                              "Coldcard",
                              "KeepKey",
                              "TrezorT",
                              "Coldcard",
                            ].includes(props.className)
                          ) {
                            return <Buy name={props.className} />;
                          }
                          if (props.className === "Binance") {
                            return <Affiliate name="Binance" />;
                          }
                          if (
                            props.className === "Coinbase" ||
                            props.className === "Kraken" ||
                            props.className === "KuCoin" ||
                            props.className === "Gemini"
                          ) {
                            return <Affiliate simple name={props.className} />;
                          }
                          return <span {...props} />;
                        },
                        code(props) {
                          const { children, className, node, ...rest } = props;
                          const matchList = /language-list/.exec(className || "");
                          const matchPros = /language-pros/.exec(className || "");
                          const matchCons = /language-cons/.exec(className || "");
                          // console.log("code>>>", className)
                          const IconComponent = matchPros
                            ? "check"
                            : matchCons
                            ? "close"
                            : ArrowForwardIcon;
                          const color = matchPros ? "success" : matchCons ? "error" : "text";
                          // eslint-disable-next-line react/prop-types
                          const list = children.split(/[£]/).map((element, i) => (
                            <MKTypography
                              variant="body1"
                              fontWeight="light"
                              color="text"
                              key={`code-list-${i}`}
                              {...rest}
                              className={className}
                            >
                              <Icon color={color} sx={{ fontWeight: "bold" }}>
                                {typeof IconComponent === "string" ? (
                                  IconComponent
                                ) : (
                                  <IconComponent />
                                )}
                              </Icon>
                              &nbsp;&nbsp;
                              <Markdown
                                components={{
                                  p: react.Fragment, // Supprime les balises <p> générées
                                }}
                              >
                                {element.trim()}
                              </Markdown>
                            </MKTypography>
                          ));
                          return matchList || matchPros || matchCons ? (
                            list
                          ) : (
                            <code {...rest} className={className}>
                              {children}
                            </code>
                          );
                        },
                        p(props) {
                          // eslint-disable-next-line react/prop-types
                          const { node, ...rest } = props;
                          return (
                            <MKTypography
                              variant="body1"
                              fontWeight="light"
                              color="text"
                              {...rest}
                            />
                          );
                        },
                        ul(props) {
                          // eslint-disable-next-line react/prop-types
                          const { node, ...rest } = props;
                          return (
                            <ul className="MuiList-root MuiList-padding css-1ye9zee" {...rest} />
                          );
                        },
                        li(props) {
                          // eslint-disable-next-line react/prop-types
                          const { node, ...rest } = props;
                          return (
                            <li className="MuiListItem-root MuiListItem-gutters css-ykjcgo">
                              <MKTypography
                                variant="body1"
                                fontWeight="light"
                                color="text"
                                {...rest}
                              />
                            </li>
                          );
                        },
                      }}
                    >
                      {content}
                    </Markdown>
                    <Box sx={{ m: 4 }} />
                    {legal ? (
                      <>
                        <Grid container spacing={3} mb={3}>
                          <Grid item>
                            <MKTypography component="span" variant="body2">
                              {t("updatedAt")} 11.09.2024
                            </MKTypography>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <Affiliate name="Binance" />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        <MainFooter />
      </MKBox>
    </>
  );
}

Article.propTypes = {
  children: PropTypes.node,
  legal: PropTypes.bool,
  name: PropTypes.string,
};

Article.defaultProps = {
  legal: false,
};

export default Article;
