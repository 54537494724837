let articles;
let legaux;

if (process.env.REACT_APP_SITE === 'petitspas') {
  articles = require('data/petitspas/articles').articles;
  legaux = require('data/petitspas/legaux').legaux
} else if (process.env.REACT_APP_SITE === 'meilleurecrypto') {
  articles = require('data/meilleurecrypto/articles').articles;
  legaux = require('data/meilleurecrypto/legaux').legaux
} else {
  throw new Error('Aucun site valide n\'a été défini.');
}

export { articles, legaux };