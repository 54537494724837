import { articles, legaux } from "data/articles";
import { articleRoutes } from "routes";


  // Fonction pour obtenir l'article en fonction du type
  export const getArticleContent = (name) => {
    return { ...articles, ...legaux }[name].content || "...";
  };
  
  export const getArticleImage = (name) => {
    return { ...articles, ...legaux }[name].image || "...";
  };
  
  export const getArticleSquareImage = (name) => {
    return { ...articles, ...legaux }[name].image_c || "...";
  };
  
  export const getArticleTitle = (t, name) => {
    return t(`articles.${name}.title`) || "...";
  };
  
  export const getArticleRoute = (name) => {
    return articleRoutes[name]?.route|| "";
  };
  